

































































































































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import axios from 'axios';
import { Commodity, DutyFieldType, isaMillCalculation } from './calculations';
import CalculatorInput from './CalculatorInput.vue';
import Dropdown from '../base/Dropdown.vue';
import DropdownItem from '../base/DropdownItem.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import Icon from '../base/Icon.vue';
import MultiStepFormHeader from './albion/MultiStepFormHeader.vue';
import { Step } from './albion/MultiStepForm.vue';
import Utils from '../../utils/Utils';

declare const d365mktformcapture: any;

@Component({
    components: { MultiStepFormHeader, CalculatorInput, Dropdown, DropdownItem, ValidationObserver, ValidationProvider, Icon }
})
export default class IsaMillCalculator extends Vue {
    /***********************
     * Vue properties
     ***********************/
    @Prop({ default: () => [] }) protected labelTranslation: any[];
    @Prop({ default: () => [] }) protected commodities: Commodity[];
    @Prop({ required: true }) protected title: string;
    @Prop({ required: true }) protected subTitle: string;
    @Prop({ required: true }) protected intro: string;
    @Prop({ required: true }) protected privacy: string;
    @Prop({ required: true }) protected confirmation1: string;
    @Prop({ required: true }) protected confirmation2: string;
    @Prop({ required: true }) protected f80Min!: number;
    @Prop({ required: true }) protected f80Max!: number;
    @Prop({ required: true }) protected p80Min!: number;
    @Prop({ required: true }) protected p80Max!: number;
    @Prop({ required: true }) protected tphMin!: number;
    @Prop({ required: true }) protected tphMax!: number;
    @Prop({ required: true }) protected labelNext: string;
    @Prop({ required: true }) protected labelBack: string;
    @Prop({ required: true }) protected labelStep: string;
    @Prop({ required: true }) protected labelRequest: string;
    @Prop({ required: true }) protected labelFirstName: string;
    @Prop({ required: true }) protected labelLastName: string;
    @Prop({ required: true }) protected labelCompany: string;
    @Prop({ required: true }) protected labelEmail: string;
    @Prop({ required: true }) protected labelEmailPlaceholder: string;
    @Prop({ required: true }) protected labelCountry: string;
    @Prop({ default: [] }) protected countries: string[];
    @Prop({ required: true }) protected labelSendReport: string;
    @Prop({ required: true }) protected errorAcceptPrivacy: string;
    @Prop() protected sendReportDescription: string;
    @Prop({ default: false, type: Boolean }) dynamicsSend: boolean;
    @Prop() dynamicsFormId: string;
    @Prop() dynamicsApiUrl: string;
    @Prop() dynamicsLibUrl: string;
    @Prop({ default: {} }) dynamicsFieldMappings: any;

    /***********************
     * Vue data
     ***********************/
    protected step = 0;
    protected commodity = 'copper-molybdenum';
    protected f80 = this.f80Min;
    protected p80 = 0;
    protected throughput = this.tphMin;
    protected firstName = '';
    protected lastName = '';
    protected company = '';
    protected email = '';
    protected country = '';
    protected recommendation = null;
    protected httpError = null;
    protected DutyFieldType = DutyFieldType;

    // f80Min – f80Max, 5 steps
    private f80Values = new Array(Math.floor((this.f80Max - this.f80Min) / 5) + 1).fill(0).map((x, i) => i * 5 + this.f80Min);
    // p80Min – p80Max, 1 steps
    private p80Values = new Array(Math.floor(this.p80Max - this.p80Min) + 1).fill(0).map((x, i) => i + this.p80Min);

    private tphValues = new Array(Math.floor(this.tphMax - this.tphMin) + 1).fill(0).map((x, i) => i + this.tphMin);

    model: any = '';

    getIcon(errors: string[], passed: boolean): string {
        if (errors.length > 0) {
            return 'invalid';
        } else if (passed) {
            return 'valid';
        }
        return '';
    }

    /***********************
     * Vue refs
     ***********************/

    /***********************
     * Vue lifecycle
     ***********************/

    created(): void {
        if (this.dynamicsSend && this.dynamicsLibUrl) {
            Utils.addScript(this.dynamicsLibUrl);
        }
    }

    /***********************
     * Vue computed
     ***********************/

    get stepHeader(): Step[] {
        return [
            {
                title: this.subTitle,
                type: 'normal'
            },
            {
                title: this.labelSendReport,
                type: 'normal'
            }
        ];
    }

    get showBackNavigation(): boolean {
        return (this.step > 0) && (this.step < this.stepHeader.length) && (this.stepHeader.length > 1);
    }

    get countryOptions(): any[] {
        const result = [];
        if (this.countries) {
            this.countries.forEach(s => {
                const keyValue = s.split(':');
                if (keyValue.length === 2) {
                    result.push({ label: keyValue[0], value: keyValue[1] });
                }
            });
        }
        return result;
    }

    /***********************
     * Vue methods
     ***********************/
    protected translate(key: string): string {
        const translation = this.labelTranslation.find(x => x.key === key);
        if (translation) {
            return translation.value;
        }
        // missing translations throw errors that are hard to track down
        console.error(`key "${key}" not found`);
        return key;
    }

    /**
     * form submit (via next button or enter key)
     * go to next form step or send form
     */
    protected async submit() {
        this.step++;
        if (this.step === 2) {
            // last step: send form
            if (this.dynamicsSend && !!d365mktformcapture) {
                // dynamics form capture
                const form = this.$el.querySelector('form');
                form.querySelectorAll('select').forEach((select: any) => {
                    if (select.forEach) {
                        select.forEach = null;
                    }
                });
                const serializedForm = d365mktformcapture.serializeForm(form, this.dynamicsFieldMappings);

                const payload = serializedForm.SerializedForm.build();
                const captureConfig = {
                    FormId: this.dynamicsFormId,
                    FormApiUrl: this.dynamicsApiUrl
                };

                d365mktformcapture.submitForm(captureConfig, payload)
                    .catch(e => {
                        console.log(e);
                    })
                    .finally(() => {
                        // submit after promise is either accepted or rejected
                        this.doSend();
                    });
            } else {
                // normal submit
                await this.doSend();
            }
        } else if (this.step > 0) {
            // make calculation with form values
            this.recommendation = isaMillCalculation(this.commodity, this.f80, this.p80, this.throughput);
        }
    }

    private async doSend() {
        const { firstName, lastName, company, email, country, recommendation } = this;
        const { commodity, f80, p80, throughput } = this;
        this.httpError = null;
        try {
            await axios.post(`${this.$contextPath}/.rest/api/v1/mill-calculation`, {
                type: 'isa-mill',
                recommendation,
                inputs: {
                    commodity,
                    f80,
                    p80,
                    throughput
                },
                firstName,
                lastName,
                company,
                email,
                country
            });
        } catch (e) {
            if (e.response?.data) {
                this.httpError = e.response.data;
            } else {
                this.translate('request_error');
            }
        }
    }

    protected back() {
        this.step = 0;
    }

    /***********************
     * Vue watcher
     ***********************/
}
