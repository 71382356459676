





















































import Vue from 'vue';
import { Component, Prop, Provide, ProvideReactive, Watch } from 'vue-property-decorator';
import { CollapseTransition } from '@ivanv/vue-collapse-transition';
import Icon from './Icon.vue';
import DropdownItem from './DropdownItem.vue';

@Component({
    components: { Icon, CollapseTransition }
})
export default class Dropdown extends Vue {
    @Prop() label: string;
    @Prop() name: string;
    @Prop() defaultValue: string;
    @Prop({ default: false, type: Boolean }) white: boolean;
    @Prop({ default: false, type: Boolean }) disabled: boolean;
    @Prop({ default: false, type: Boolean }) required: boolean;
    @Prop({ default: false, type: Boolean }) error: boolean;
    @Prop({ default: false, type: Boolean }) passed: boolean;
    @Prop({ default: true, type: Boolean }) rounded: boolean;
    @Prop({ default: false, type: Boolean }) higherMenu: boolean;
    @Prop() title!: string;
    @Prop({ default: 'text-para-s h-6 truncate' }) textClasses!: string;
    @Prop({ default: false, type: Boolean }) flex!: boolean;
    @Prop({ default: false, type: Boolean }) searchable!: boolean;
    @Prop() value: string | number;
    @Prop() describedBy: string; // id of description or error element, needed for a11y

    @Provide('register')
    childRegistration = this.registerItem;

    @ProvideReactive()
    currentSearch = '';

    open = false;
    activeTitle = this.title || '';
    activeValue: string | number = this.title || '';
    items: InstanceType<typeof DropdownItem>[] = [];

    mounted(): void {
        document.addEventListener('click', this.onOutsideClick);
        if (this.defaultValue) {
            this.select(this.defaultValue);
        } else if (this.value) {
            this.select(this.value);
        }
    }

    handleClick(): void {
        if (!this.disabled) {
            this.open = !this.open;
            this.$emit(this.open ? 'open' : 'close');
        }
    }

    select(value: string | number): void {
        const item = this.items.find(i => i.value === value);
        if (item) {
            this.activeTitle = item.label;
            this.activeValue = item.value;
            this.$emit('input', value);
        } else {
            this.activeTitle = this.title;
            this.activeValue = this.title;
        }
    }

    beforeDestroy(): void {
        document.removeEventListener('click', this.onOutsideClick);
    }

    // called by child items
    registerItem(item: InstanceType<typeof DropdownItem>): void {
        this.items.push(item);
        item.$on('click', (_: string, value: string) => {
            this.select(value);
            this.open = false;
        });
    }

    onOutsideClick(evt): void {
        let targetElement = evt.target;

        do {
            if (targetElement === this.$el) {
                return;
            }
            targetElement = targetElement.parentNode;
        } while (targetElement);
        this.open = false;
    }

    @Watch('value')
    watchValue(value: string): void {
        this.select(value);
    }
}
