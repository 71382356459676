



















































import Vue from 'vue';
import { Component, Emit, Prop, Ref, Watch } from 'vue-property-decorator';
import Icon from './Icon.vue';
import LoadingSpinner from './LoadingSpinner.vue';

@Component({
    components: {
        LoadingSpinner,
        Icon
    }
})
export default class Lightbox extends Vue {
    @Prop({ required: true }) src: string;
    @Prop() content: string;
    @Prop({ default: '' }) caption: string;
    @Prop({ default: '' }) altTitle: string;
    @Prop({ default: false }) hideMobileIcon: boolean;
    @Prop({ required: true }) value: boolean;

    @Ref() imageEl: HTMLImageElement;
    @Ref() svgContainer: HTMLDivElement;
    @Ref() captionEl: HTMLDivElement;
    @Ref() lightbox: HTMLDivElement;
    @Ref() dialogEL: HTMLDialogElement;

    loading = false;

    mounted(): void {
        if (!this.content) {
            // show loading spinner
            this.loading = true;
        }

        // append the lightbox itself to the doc body
        // background: images might be placed in a slider which uses transformations which will break 'position: fixed'
        document.body.appendChild(this.lightbox);
    }

    beforeDestroy(): void {
        // upon destroying the component, move the lightbox back, otherwise it will be stuck in the dom
        this.$el.appendChild(this.lightbox);
    }

    @Emit('input')
    protected hide(): boolean {
        document.body.style.overflow = '';

        if (this.dialogEL.open) {
            this.dialogEL.close();
        }

        this.dialogEL.removeEventListener('close', this.hide);
        window.removeEventListener('resize', this.handleResize);
        return false;
    }

    @Emit('input')
    protected show(): boolean {
        document.body.style.overflow = 'hidden';

        if (this.dialogEL && !this.dialogEL.open) {
            this.dialogEL.showModal();
        }

        // make sure hide method is called when dialog is closed by pressing escape
        this.dialogEL.addEventListener('close', this.hide);

        this.onLoad();
        window.addEventListener('resize', this.handleResize);
        return true;
    }

    protected onLoad(): void {
        this.loading = false;
        setTimeout(() => {
            this.handleResize();
        }, 100);
    }

    protected handleResize(): void {
        if ((this.imageEl || this.svgContainer) && this.captionEl) {
            const width = this.imageEl ? this.imageEl.width : this.svgContainer ? this.svgContainer.offsetWidth : 0;
            this.captionEl.style.width = `${width}px`;
        }
    }

    get alt(): string {
        return this.getCleanText(this.altTitle || '');
    }

    protected getCleanText(text: string): string {
        const el = document.createElement('div');
        el.innerHTML = text;
        return el.innerText;
    }

    @Watch('value')
    watchValue(newVal: boolean): void {
        if (newVal) {
            this.show();
        } else {
            this.hide();
        }
    }
}
