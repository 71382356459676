

































import Vue from 'vue';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import brightcovePlayerLoader from '@brightcove/player-loader';

@Component
export default class VideoModal extends Vue {
    @Prop({ required: true }) videoId: string | number;
    @Prop({ required: true }) accountId: string;
    @Prop({ required: true }) playerId: string;

    @Ref() embed!: HTMLElement
    @Ref() dialogEL: HTMLDialogElement;

    videoRef = null;
    containerHeight = '0';
    active = false;

    closeVisible = false;
    timer: any = '';

    mounted(): void {
        // move element to body to prevent layout breaking (e.g. transforms in parent container)
        document.body.append(this.$el);
    }

    handleCloseButton(): void {
        this.closeVisible = true;
        if (this.timer) clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.closeVisible = false;
        }, 3000);
    }

    @Watch('videoId')
    async open() {
        if (this.videoId) {
            await this.loadVideo();
            this.openModal();
        } else if (this.active) {
            this.close();
        }
    }

    async loadVideo() {
        const success = await brightcovePlayerLoader({
            refNode: this.embed,
            accountId: this.accountId,
            playerId: this.playerId,
            videoId: this.videoId,
            embedOptions: {
                tagName: brightcovePlayerLoader.EMBED_TAG_NAME_VIDEO,
                responsive: {
                    aspectRatio: '16:9'
                }
            }
        });

        this.videoRef = success.ref;

        // Wait a frame for the video to be inserted
        window.requestAnimationFrame(() => {
            this.containerHeight = `${this.embed.clientHeight}px`;
        });

        this.videoRef.on('loadedmetadata', () => {
            if (this.videoRef) {
                this.videoRef.play();
            }
        });
    }

    close() {
        if (this.videoRef) {
            this.videoRef.pause();
            this.videoRef = false;
        }

        this.closeModal();

        // Remove the old video and clear the height
        this.embed.innerHTML = '';
        this.containerHeight = '0';
        this.$emit('close');
    }

    openModal() {
        this.active = true;

        document.body.style.overflow = 'hidden';

        if (this.dialogEL && !this.dialogEL.open) {
            this.dialogEL.showModal();
        }
    }

    closeModal() {
        this.active = false;

        document.body.style.overflow = '';

        if (this.dialogEL.open) {
            this.dialogEL.close();
        }
    }
}
