



































import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject, Prop } from 'vue-property-decorator';
import debounce from 'lodash/debounce';
import PlayButton from '../../video/PlayButton.vue';
import Utils from '../../../utils/Utils';

@Component({
    components: {
        PlayButton
    }
})
export default class Youtube extends Vue {
    // preserve comment: xl:h-16 xl:w-16 md:h-10 md:w-10
    @Prop() data: Record<string, string>;

    @Inject() isVertical: boolean;
    @Inject() showChannel: boolean;

    redraw = debounce(this.$redrawVueMasonry, 300);

    show = false;

    mounted() {
        Utils.observeIntersection(this.$refs.observable, () => { this.show = true; });
    }

    imageLoaded(): void {
        this.redraw();
    }

    go(): void {
        window.open(this.data.link, '_blank').focus();
    }
}
