

























































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject, InjectReactive, Prop } from 'vue-property-decorator';
import { GolfEventLabels, Participant } from './GolfEventTypes';
import { ValidationObserver } from 'vee-validate';
import ArrowButton from '../base/ArrowButton.vue';

@Component({
    components: {
        ValidationObserver,
        ArrowButton
    }
})
export default class GolfEventPersonalDataStep extends Vue {
    @Prop({ required: true }) labels: GolfEventLabels;

    @InjectReactive()
    participant: Participant;

    @Inject()
    updateData: (background: boolean) => void;

    @Inject()
    updateProperty: (name: string, value: any) => void;

    @Inject()
    nextStep: () => void;

    @Inject()
    toOverview: () => void;

    mounted(): void {
        this.$el.scrollIntoView({ behavior: 'smooth' });
    }

    onFocusChange(passed: boolean, name: string, value: any): void {
        if (passed) {
            this.updateProperty(name, value);
        }
    }

    onSubmit(validate: () => Promise<boolean>): void {
        validate().then((valid) => {
            if (valid) {
                this.nextStep();
            }
        });
    }
}
