































import Vue from 'vue';
import { Component, Emit, Inject, Prop } from 'vue-property-decorator';
import { CollapseTransition } from '@ivanv/vue-collapse-transition';
import ArrowButton from './ArrowButton.vue';

@Component({
    components: {
        ArrowButton,
        CollapseTransition
    }
})
export default class Accordion extends Vue {
    @Prop() title: string;
    @Prop() value: boolean;
    @Prop() anchor: string;
    @Prop({ default: false, type: Boolean }) isLast: boolean;

    @Inject() isNestedAccordion: boolean;

    @Emit('input')
    toggle() {
        if (!this.value) this.setDeeplink(this.anchor);
        return !this.value;
    }

    setDeeplink(value: string): void {
        if (value) {
            if (window.history.pushState) {
                window.history.pushState(null, null, `#${value}`);
            } else {
                window.location.hash = `#${value}`;
            }
        }
    }
}
