












































































import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import Utils from '../../utils/Utils';

export interface Member {
    uuid: string;
    name: string;
    job: string;
    image: string;
    smallImage: string;
    workplace: string;
    experience: string;
    appointed: string;
    priority: number;
}

export interface LeadershipLabels {
    close: string;
    viewProfile: string;
    appointed: string;
    experience: string;
}

@Component
export default class Leadership extends Vue {
    @Prop({ default: () => [] }) members: Member[];
    @Prop({ default: {} }) labels: LeadershipLabels;
    @Prop({ default: false }) inContentGroup: boolean;

    currentProfile = '';

    get currentMember(): Member {
        return this.currentProfile ? this.members.find(m => m.uuid === this.currentProfile) : undefined;
    }

    get imgSize(): Record<string, boolean> {
        return {
            'md:w-50 md:h-50': !this.inContentGroup,
            'md:w-40 md:h-40': this.inContentGroup
        };
    }

    created(): void {
        const uuid = Utils.getUrlParam('uuid');
        if (uuid && this.members.findIndex(m => m.uuid === uuid) >= 0) {
            this.currentProfile = uuid;
        }
    }

    showProfile(uuid: string): void {
        this.currentProfile = uuid;
    }

    close(): void {
        this.currentProfile = '';
    }
}
