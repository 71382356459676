






















































import { Component, Prop, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import Icon from '../base/Icon.vue';
import { Operation, Region, SubOperation, SubRegion } from './SocioEconomicMapUtils';
import Utils from '../../utils/Utils';
import SocioEconomicMapSelection from './SocioEconomicMapSelection.vue';

@Component({
    components: {
        Icon,
        SocioEconomicMapSelection
    }
})
export default class SocioEconomicMapFiltering extends Vue {
    /***********************
     * Vue Props
     ***********************/
    @Prop({ default: () => ([]) }) regions: Array<Region>;
    @Prop({ default: () => ([]) }) operations: Array<Operation>;
    @Prop({ default: null }) currentOperation: Operation;
    @Prop({ default: null }) currentRegion: Region;

    @Prop({ default: '' }) regionLabel: string;
    @Prop({ default: '' }) resetFilterLabel: string;
    @Prop({ default: '' }) operationLabel: string;
    @Prop({ default: '' }) searchLabel: string;
    @Prop({ default: '' }) filterByLabel: string;

    @Watch('currentOperation')
    onCurrentOperationMutated() {
        if (this.currentOperation !== null) {
            this.currentQuery = 'Operation';
        } else if (this.currentRegion === null) {
            this.currentQuery = null;
        }
    }

    @Watch('currentRegion')
    onCurrentRegionMutated() {
        if (this.currentRegion !== null) {
            this.currentQuery = 'Region';
        } else if (this.currentOperation === null) {
            this.currentQuery = null;
        }
    }

    /***********************
     * Vue Data
     ***********************/

    currentQuery: string = null;

    /***********************
     * Vue Methods
     ***********************/

    closeFiltering() {
        Utils.unlockBodyScroll('md');
        this.$emit('closeFiltering');
    }

    private setRegion(region: SubRegion) {
        this.$emit('setRegion', region);
        this.currentQuery = 'Region';
    }

    private setOperation(operation: SubOperation) {
        this.$emit('setOperation', operation);
        this.currentQuery = 'Operation';
    }

    private unsetQuery() {
        this.currentQuery = null;
        this.$emit('unset');
    }
}
