

























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Utils from '../../utils/Utils';
import PlayButton from './PlayButton.vue';

@Component({
    components: { PlayButton }
})
export default class MovingImageVideo extends Vue {
    @Prop({ default: '' }) id: string;
    @Prop({ required: true }) playerId: string;
    @Prop({ required: true }) videoId: string;
    @Prop({ default: '' }) placeholderImageUrl: string;
    @Prop({ default: 1 }) numOfComponents: number;
    @Prop({ default: 'landscape' }) rendition: string;
    @Prop({ default: false }) fullScreen: boolean;
    @Prop({ default: false }) narrow: boolean;
    @Prop({ default: false }) isSlider: boolean;

    firstPlay = false;
    videoContainerId = '';

    /***********************
     * Vue lifecycle
     ***********************/
    async mounted() {
        this.videoContainerId = this.id === '' ? 'video-player-' + Math.random().toString(16).slice(2) : this.id;

        this.$nextTick(() => {
            Utils.addScript(
                'https://e.video-cdn.net/v2/embed.js',
                {},
                document.getElementsByTagName('head')[0]
            );
        });
    }

    get showPlaceHolderImage() {
        return !this.firstPlay &&
            this.placeholderImageUrl !== undefined &&
            this.placeholderImageUrl !== '';
    }

    get padding(): string {
        return this.rendition === 'landscape' ? 'pt-16/9' : 'pt-3/4';
    }

    get tabletPlayButtonSize(): number {
        // preserve comment: md:h-24 md:w-24 md:h-16 md:w-16 md:h-10 md:w-10
        if (this.fullScreen) {
            return 24;
        } else if (this.isSlider || (this.numOfComponents % 2 === 0)) {
            return 16;
        } else if (this.numOfComponents % 3 === 0) {
            return 10;
        }
        return 20;
    }

    get desktopPlayButtonSize(): number {
        // preserve comment: xl:h-20 xl:w-20 xl:h-16 xl:w-16 xl:h-10 xl:w-10
        if (this.fullScreen) {
            return 24;
        } else if (this.narrow) {
            if (this.isSlider || (this.numOfComponents % 2 === 0)) {
                return 16;
            } else if (this.numOfComponents % 3 === 0) {
                return 10;
            } else {
                return 20;
            }
        } else if (this.isSlider || (this.numOfComponents % 2 === 0)) {
            return 20;
        } else if (this.numOfComponents % 3 === 0) {
            return 16;
        }
        return 24;
    }

    playVideo(): void {
        // eslint-disable-next-line no-undef
        const player = VideoPlayer.Collection.getPlayerById(this.videoContainerId);
        // see https://help.movingimage.com/docs/controlling-the-player
        player.play();

        this.firstPlay = true;
    }
}
