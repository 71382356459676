




































import Vue from 'vue';
import { Component, Emit, Prop, Ref, Watch } from 'vue-property-decorator';
import GcButton from './GcButton.vue';
import Icon from './Icon.vue';
import Cookies from 'js-cookie';

@Component({
    components: {
        GcButton,
        Icon
    }
})
export default class ContentOverlay extends Vue {
    @Prop({ default: false, type: Boolean }) value: boolean;
    @Prop({ default: false, type: Boolean }) showOnLoad: boolean;
    @Prop({ default: false, type: Boolean }) showAlways: boolean;
    @Prop({ default: false, type: Boolean }) narrow: boolean;
    @Prop({ default: 500 }) delay: number;
    @Prop() buttonLabel: string;

    @Ref() parentContainer: HTMLDivElement;
    @Ref() contentContainer: HTMLDivElement;
    @Ref() dialogEL: HTMLDialogElement;

    scrollable = false;

    mounted(): void {
        const alreadyShown = !!Cookies.get('gc-overlay-shown');
        if (this.showAlways || (this.showOnLoad && !alreadyShown)) {
            setTimeout(() => this.show(), this.delay);
        }
    }

    protected checkIfScrollable(): void {
        this.scrollable = !!this.parentContainer && !!this.contentContainer &&
            (this.parentContainer.clientHeight < this.contentContainer.clientHeight);
    }

    @Emit('cta')
    protected ctaClick(): void {
        this.hide();
    }

    @Emit('input')
    @Emit('hide')
    protected hide(): boolean {
        document.body.style.overflow = '';

        if (this.dialogEL.open) {
            this.dialogEL.close();
        }

        this.dialogEL.removeEventListener('close', this.hide);
        window.removeEventListener('resize', this.checkIfScrollable);
        return false;
    }

    @Emit('input')
    @Emit('show')
    protected show(): boolean {
        document.body.style.overflow = 'hidden';

        if (this.dialogEL && !this.dialogEL.open) {
            this.dialogEL.showModal();
        }

        // make sure hide method is called when dialog is closed by pressing escape
        this.dialogEL.addEventListener('close', this.hide);
        window.addEventListener('resize', this.checkIfScrollable);

        Cookies.set('gc-overlay-shown', 'true');

        this.$nextTick(() => this.checkIfScrollable());
        return true;
    }

    @Watch('value')
    watchValue(newVal: boolean): void {
        if (newVal) {
            this.show();
        } else {
            this.hide();
        }
    }
}
