





























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Provide, Watch } from 'vue-property-decorator';
import TabHeader from '../base/TabHeader.vue';
import Utils from '../../utils/Utils';
import NavigationStore from '../../store/NavigationStore';
import { getModule } from 'vuex-module-decorators';

@Component({
    components: {
        TabHeader
    }
})
export default class ContentGroup extends Vue {
    @Prop({ default: 'accordion' }) type: string;
    @Prop({ default: false, type: Boolean }) showAll: boolean;
    @Prop({ default: false, type: Boolean }) inContentGroup!: boolean;
    @Prop({ default: true, type: Boolean }) narrow!: boolean;
    @Prop({ default: false, type: Boolean }) scrollableContent!: boolean;
    @Prop({ default: false, type: Boolean }) highlight!: boolean;
    @Prop({ default: null }) defaultIndex!: number;
    @Prop({ default: 0 }) itemsLength!: number;
    @Prop({ default: null }) anchor!: string;
    @Prop() showAllLabel: string;

    currentIndex = this.defaultIndex !== null ? this.defaultIndex : this.isAccordion ? null : 0;
    titles: string[] = [];
    anchors: Array<string|null> = [];

    navStore: NavigationStore = getModule(NavigationStore);

    @Provide()
    get isAccordion(): boolean {
        return this.type === 'accordion';
    }

    @Provide()
    get isNestedAccordion(): boolean {
        return this.isAccordion && this.inContentGroup;
    }

    get activatedTab(): string {
        return this.navStore.activatedTab;
    }

    mounted(): void {
        Vue.nextTick(() => {
            // eslint-disable-next-line
            this.titles = this.$children.filter(c => !!(c as any).title).map(c => (c as any).title);
            this.anchors = this.$children.filter(c => !!(c as any).title)
                .map(c => (c as any).anchor ? (c as any).anchor : Utils.cleanString((c as any).title));
            // show all -> add this option as last tab
            if (this.showAll) {
                this.titles.push(this.showAllLabel);
            }
            this.loadDeepLink();
        });
        // listen for changes of the anchor
        addEventListener('hashchange', this.loadDeepLink);
    }

    loadDeepLink(): void {
        const itemIds: string[] = this.getItemsFromUrl();
        itemIds.forEach(id => this.presetItem(id));
    }

    checkActivatedTab(anchor: string): void {
        if (anchor) {
            const index = this.anchors.findIndex(a => a === anchor);
            if (index >= 0) {
                this.currentIndex = index;
            }
        }
    }

    presetItem(itemId: string, sectionId?: string): void {
        if (!itemId || (sectionId && this.anchor !== sectionId)) {
            this.currentIndex = this.defaultIndex;
            return;
        }

        let itemIndex: number;
        if (this.anchors.includes(itemId)) {
            itemIndex = this.anchors.indexOf(itemId);
        } else {
            // check if target item is contained in one of the content group items
            const targetItem = document.getElementById(itemId);
            const list = this.$children.filter(child => {
                return child.$el.contains(targetItem);
            });

            if (!list.length) return; // targetItem not in this content group -> nothing to do

            // targetItem found,
            itemIndex = (list[0] as any).index;
        }
        this.currentIndex = itemIndex >= 0 ? itemIndex : this.defaultIndex;
        this.$children
            .filter(c => (c as any).index === itemIndex)
            .forEach(c => {
                setTimeout(() => {
                    (c as any).open = true;
                }, 0);
            });

        // Trigger scroll after synchronous operationFilters have been executed. In case of accordion,
        // wait for animation to finish.
        // Let the target element always be the last id found in the url to ensure a deepest child is visible.
        if (this.getItemsFromUrl().indexOf(itemId) === this.getItemsFromUrl().length - 1) {
            let el = document.getElementById(itemId);
            if (!el) {
                el = document.getElementById(`item-${this._uid}-${itemIndex}`);
            }

            if (el) {
                setTimeout(() => {
                    el.scrollIntoView({ behavior: 'smooth' });
                }, this.isAccordion ? 300 : 0);
            }
        }
    }

    getItemsFromUrl() {
        return window.location.hash.substring(1).split(':');
    }

    @Watch('activatedTab')
    watchActivatedTab(newVal: string): void {
        if (newVal) {
            this.checkActivatedTab(newVal);
        }
    }
}
