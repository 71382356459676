























import { Component, Emit, Prop, Ref, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import TabHeader from '../base/TabHeader.vue';

@Component({
    components: {
        TabHeader
    }
})
export default class GlobalNavigation extends Vue {
    @Prop() tabs: Record<string, string>;
    @Prop({ default: '' }) region: string;
    @Prop({ default: false }) opened: boolean;
    @Prop({ default: '' }) logoPath: string;
    @Prop({ default: '' }) logoAlt: string;
    @Ref() dialogEL: HTMLDialogElement;

    currentTab = 0;

    mounted(): void {
        // append the dialog itself to the doc body
        // background: images might be placed in a slider which uses transformations which will break 'position: fixed'
        document.body.appendChild(this.dialogEL);
    }

    beforeDestroy(): void {
        // upon destroying the component, move the lightbox back, otherwise it will be stuck in the dom
        this.$el.appendChild(this.dialogEL);
    }

    get tabTitles(): string[] {
        return Object.values(this.tabs);
    }

    created(): void {
        if (this.region && this.tabs[this.region]) {
            this.currentTab = Object.keys(this.tabs).indexOf(this.region);
        }
    }

    show(): void {
        document.body.style.overflow = 'hidden';

        if (this.dialogEL && !this.dialogEL.open) {
            this.dialogEL.showModal();
        }

        // make sure hide method is called when dialog is closed by pressing escape
        this.dialogEL.addEventListener('cancel', (ev) => {
            ev.preventDefault();
            this.hide(); // call our hide-method to close with delay and make sure transition is visible
        }, { once: true });
    }

    @Emit('hide')
    hide(): void {
        document.body.style.overflow = '';

        if (this.dialogEL.open) {
            // Timeout matches the animation time for it to fade.
            setTimeout(() => this.dialogEL.close(), 300);
        }
    }

    @Watch('opened')
    handleOpened(newValue: boolean): void {
        if (newValue) {
            this.show();
        } else {
            this.hide();
        }
    }
}
