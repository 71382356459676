





























































import Vue from 'vue';
import { Inject, InjectReactive, Prop, Ref } from 'vue-property-decorator';
import { Comment, CommentLabels } from './PublicUserComments.vue';
import Component from 'vue-class-component';
import GcButton from '../base/GcButton.vue';
import ArrowButton from '../base/ArrowButton.vue';

@Component({
    components: {
        GcButton,
        ArrowButton
    }
})
export default class UserComment extends Vue {
    @Prop({ required: true }) comment: Comment;
    @Prop({ required: true }) labels: CommentLabels;
    @Prop({ default: false, type: Boolean }) nested: boolean;
    @Prop({ default: false, type: Boolean }) isAdmin: boolean;
    @Prop({ default: false, type: Boolean }) isLast: boolean;
    @Prop({ default: false, type: Boolean }) disabled: boolean;
    @Prop({ required: true }) index: string | number;
    @Prop({ required: true }) fullName: string;
    @Prop({ required: true }) email: string;
    @Prop() parentId: number;

    @Ref() text: HTMLParagraphElement;
    @Ref() edit: HTMLTextAreaElement;

    @InjectReactive() loading: boolean;

    @Inject() replyModel: Record<string | number, string>;
    @Inject() replyMode: Record<string | number, boolean>;
    @Inject() editMode: Record<string | number, boolean>;

    @Inject() formatText: (text: string) => string;
    @Inject() autosize: (ev: Event) => void;
    @Inject() toggleReply: (comment: Comment, key: string | number) => void;
    @Inject() toggleEditMode: (key: string | number) => void;

    @Inject() getComments: () => Promise<void>;
    @Inject() replyToComment: (id: number, key: string | number) => Promise<void>
    @Inject() deleteComment: (id: number) => Promise<void>;
    @Inject() updateComment: (comment: Comment, key: string | number) => Promise<void>;

    toggle(): void {
        this.toggleReply(this.comment, this.index);
        if (this.replyMode[this.index]) {
            this.$nextTick(() => this.edit.focus());
        }
    }
}
