
































































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import axios from 'axios';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { CalcResult, Commodity, Duty, DutyFieldType, JamesonCellDuties, nestObjectKeys, objectKeysToCamelCase, round } from './calculations';
import CalculatorInput from './CalculatorInput.vue';
import JamesonCellResults from './JamesonCellResults.vue';
import Dropdown from '../base/Dropdown.vue';
import DropdownItem from '../base/DropdownItem.vue';
import Icon from '../base/Icon.vue';
import MultiStepFormHeader from './albion/MultiStepFormHeader.vue';
import { Step } from './albion/MultiStepForm.vue';
import Utils from '../../utils/Utils';

declare const d365mktformcapture: any;

@Component({
    components: {
        CalculatorInput,
        JamesonCellResults,
        MultiStepFormHeader,
        Dropdown,
        DropdownItem,
        ValidationObserver,
        ValidationProvider,
        Icon
    }
})
export default class JamesonCellCalculator extends Vue {
    /***********************
     * Vue properties
     ***********************/
    @Prop({ required: true }) protected title: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Prop({ default: () => [] }) protected labelTranslation: any[];
    @Prop({ default: () => [] }) protected commodities: Commodity[];
    @Prop({ default: () => [] }) protected isamillCommodities: Commodity[];
    @Prop({ required: true }) protected confirmation1: string;
    @Prop({ required: true }) protected confirmation2: string;
    @Prop({ required: true }) protected intro: string;
    @Prop({ required: true }) protected privacy: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Prop({ default: () => [] }) protected steps: any[];

    @Prop({ required: true }) protected labelNext: string;
    @Prop({ required: true }) protected labelBack: string;
    @Prop({ required: true }) protected labelStep: string;
    @Prop({ required: true }) protected labelRequest: string;
    @Prop({ required: true }) protected labelFirstName: string;
    @Prop({ required: true }) protected labelLastName: string;
    @Prop({ required: true }) protected labelCompany: string;
    @Prop({ required: true }) protected labelEmail: string;
    @Prop({ required: true }) protected labelEmailPlaceholder: string;
    @Prop({ required: true }) protected labelCountry: string;
    @Prop({ default: [] }) protected countries: string[];
    @Prop({ default: 'Results' }) protected labelResultsSuffix: string;
    @Prop({ required: true }) protected errorAcceptPrivacy: string;
    @Prop({ default: false, type: Boolean }) dynamicsSend: boolean;
    @Prop() dynamicsFormId: string;
    @Prop() dynamicsApiUrl: string;
    @Prop() dynamicsLibUrl: string;
    @Prop({ default: {} }) dynamicsFieldMappings: any;

    /***********************
     * Vue data
     ***********************/
    protected step = 0;
    protected commodity = 'copper';
    protected duty = 0;
    protected duties = JamesonCellDuties;
    protected calculationValues: Record<string, unknown> = {};
    protected firstName = '';
    protected lastName = '';
    protected company = '';
    protected email = '';
    protected country = '';
    protected recommendation = null;
    protected results: CalcResult[][] = [];
    protected httpError = null;

    model: any = '';

    getIcon(errors: string[], passed: boolean): string {
        if (errors.length > 0) {
            return 'invalid';
        } else if (passed) {
            return 'valid';
        }
        return '';
    }

    /***********************
     * Vue lifecycle
     ***********************/

    created(): void {
        if (this.dynamicsSend && this.dynamicsLibUrl) {
            Utils.addScript(this.dynamicsLibUrl);
        }
    }

    /***********************
     * Vue computed
     ***********************/

    get calcValuesStr(): string {
        return Object.entries(this.calculationValues).map(x => x.join(':')).join('/');
    }

    get currentDuty(): Duty {
        return this.duties[this.duty];
    }

    get stepHeader(): Step[] {
        const steps: Step[] = [
            {
                title: this.translate('commodity'),
                type: 'normal'
            }
        ];

        this.currentDuty.steps.forEach((step, i) => {
            steps.push({
                title: this.getStepTitle(step.key),
                type: i === 0 ? 'normal' : 'intermediate'
            });
        });

        steps.push({
            title: this.getStepTitle('send'),
            type: 'normal'
        });

        return steps;
    }

    get showBackNavigation(): boolean {
        return (this.step > 0) && (this.step < this.stepHeader.length) && (this.stepHeader.length > 1);
    }

    get countryOptions(): any[] {
        const result = [];
        if (this.countries) {
            this.countries.forEach(s => {
                const keyValue = s.split(':');
                if (keyValue.length === 2) {
                    result.push({ label: keyValue[0], value: keyValue[1] });
                }
            });
        }
        return result;
    }

    /***********************
     * Vue methods
     ***********************/

    /**
     * returns the translation with the given key
     * logs an error and returns the key itself if it is not found
     */
    protected translate(key: string): string {
        const translation = this.labelTranslation.find(x => x.key === key);
        if (translation) {
            return translation.value;
        }
        // missing translations throw errors that are hard to track down
        console.error(`key "${key}" not found`);
        return key;
    }

    // gets the title with the given step key from the steps array
    protected getStepTitle(key): string {
        const step = this.steps.find(x => x.key === key);
        return step ? step.title : '';
    }

    // gets the description with the given step key from the steps array
    protected getStepDescription(key): string {
        const step = this.steps.find(x => x.key === key);
        return step ? step.description : '';
    }

    /**
     * form submit (on next button or enter key)
     * go to next step or send form
     */
    protected async submit() {
        // duty selected => setup calculation values (only once)
        if (this.step === 0) {
            Vue.set(this.calculationValues, 'commodity', this.commodity);
            this.currentDuty.steps.forEach(step => {
                step.fields.forEach(field => {
                    Vue.set(this.calculationValues, field.key, field.type === DutyFieldType.DROPDOWN ? field.values[0] : undefined);
                });
            });
        }
        // make calculations for current step and push to results
        if (this.step > 0 && this.step <= this.currentDuty.steps.length) {
            const calc = this.currentDuty.steps[this.step - 1].calculation(this.calculationValues, [].concat(...this.results));
            this.results.push(calc);
        }

        this.step++;

        // last step: check if contact form is filled and validate
        if (this.step === this.currentDuty.steps.length + 1) {
            // reduce calculation results into one object
            this.recommendation = this.results.reduce((rec, step) => {
                const tmp = rec;
                step.forEach(result => {
                    if (result.display || result.labelKey.includes('cell_selection')) {
                        tmp[result.labelKey] = typeof result.value === 'number' ? round(result.value, 1, true) : result.value;
                    }
                });
                return tmp;
            }, {});
        } else if (this.step > this.currentDuty.steps.length + 1) {
            if (this.dynamicsSend && !!d365mktformcapture) {
                // dynamics form capture
                const form = this.$el.querySelector('form');
                form.querySelectorAll('select').forEach((select: any) => {
                    if (select.forEach) {
                        select.forEach = null;
                    }
                });
                const serializedForm = d365mktformcapture.serializeForm(form, this.dynamicsFieldMappings);

                const payload = serializedForm.SerializedForm.build();
                const captureConfig = {
                    FormId: this.dynamicsFormId,
                    FormApiUrl: this.dynamicsApiUrl
                };

                d365mktformcapture.submitForm(captureConfig, payload)
                    .catch(e => {
                        console.log(e);
                    })
                    .finally(() => {
                        // submit after promise is either accepted or rejected
                        this.doSend();
                    });
            } else {
                // normal submit
                await this.doSend();
            }
        }
    }

    private async doSend() {
        // after last step. send to backend
        const { firstName, lastName, company, email, country } = this;
        this.httpError = null;
        try {
            await axios.post(`${this.$contextPath}/.rest/api/v1/mill-calculation`, {
                type: 'jameson-cell',
                recommendation: nestObjectKeys(objectKeysToCamelCase(this.recommendation)),
                duty: this.translate(this.duties[this.duty].key),
                inputs: this.calculationValues,
                firstName,
                lastName,
                company,
                email,
                country
            });
        } catch (e) {
            if (e.response?.data) {
                this.httpError = e.response.data;
            } else {
                this.translate('request_error');
            }
        }
    }

    // go back one step and delete last result
    protected back() {
        this.step--;
        this.results.pop();
    }

    // get calculation results of given step
    protected getStepResults(step: number): CalcResult[] {
        if (this.results.length <= step) {
            return [];
        }
        return this.results[step].filter(res => res.display).map(x => ({ ...x, label: this.translate(x.labelKey) }));
    }

    // get translations of result groups
    protected getResultGroups(step: number): { key: string; value: string }[] {
        if (this.results.length <= step) {
            return [];
        }
        return Object.keys(
            this.results[step]
                .filter(res => res.display)
                .reduce((g, r) => {
                    const tmp = g;
                    tmp[r.labelKey.split('.').shift().replace(/\d/g, '')] = '';
                    return tmp;
                }, {})
        )
            .map(x => ({ key: x, value: this.translate(x) }));
    }

    protected getDependency(dependency): (x) => boolean {
        if (!dependency) {
            return () => true;
        }
        return x => dependency.comparator(x, ...dependency.keys.map(key => this.calculationValues[key]));
    }
}
